import Axios from "axios";
import { Navigate } from "react-router-dom";
import { server, serverAPI } from "./envConfig";
import Moment from "moment";
import { formatDistanceStrict, formatDistanceToNow, formatDuration, intervalToDuration } from "date-fns";
//url for production
export var url = "";
if (process.env.NODE_ENV === "development") {
  url = "";
} else {
  url = window.location.host.split("/")[1];
  if (url) {
    url = `/${window.location.host.split("/")[1]}`;
  } else url = process.env.PUBLIC_URL; /// ADD YOUR CPANEL SUB-URL
}

//Function to validate and return errors for a form
export const checkForm = (formData) => {
  let errorState = {};
  Object.keys(formData).forEach((item) => {
    if (formData[item] === null || formData[item] === "") {
      errorState[item] = "This field is required";
    }
  });
  return errorState;
};

//Function that returns the first or first two letters from a name
export const findUpper = (string) => {
  let extractedString = [];

  for (var i = 0; i < string?.length; i++) {
    if (
      string.charAt(i) === string.charAt(i).toUpperCase() &&
      string.charAt(i) !== " "
    ) {
      extractedString.push(string.charAt(i));
    }
  }
  if (extractedString?.length > 1) {
    return extractedString[0] + extractedString[1];
  } else {
    return extractedString[0];
  }
};

//Function that calculates the from current date
export const setDeadline = (days) => {
  let todayDate = new Date();
  var newDate = new Date(todayDate);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

// Function to set deadline for projects
export const setDeadlineDays = (deadline) => {
  var currentDate = new Date();
  var difference = deadline.getTime() - currentDate.getTime();
  var days = Math.ceil(difference / (1000 * 3600 * 24));
  return days;
};

//Date formatter function
export const dateFormatterAlt = (date, reverse) => {
  let d = date.getDate();
  let m = date.getMonth();
  let y = date.getFullYear();
  reverse ? (date = m + "-" + d + "-" + y) : (date = y + "-" + d + "-" + m);
  return date;
};

//Date formatter function
export const dateFormatter = (date, reverse) => {
  var dateformat = date.split("-");
  //var date = dateformat[1]+"-"+dateformat[2]+"-"+dateformat[0];
  reverse
    ? (date = dateformat[2] + "-" + dateformat[0] + "-" + dateformat[1])
    : (date = dateformat[1] + "-" + dateformat[2] + "-" + dateformat[0]);
  return date;
};

export const parseMilliseconds = (milliseconds) => {
  // eslint-disable-next-line eqeqeq
  if (!milliseconds == undefined) return null;

  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  const remainingSeconds = seconds % 60;
  const remainingMinutes = minutes % 60;

  return `${hours.toString().padStart(2, "0")}:${remainingMinutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`
}

export const parseSeconds = (seconds) => {
  // eslint-disable-next-line eqeqeq
  if (seconds == undefined) return null;

  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  const remainingSeconds = seconds % 60;
  const remainingMinutes = minutes % 60;

  return `${hours.toString().padStart(2, "0")}:${remainingMinutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
}

//Month Names
export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

//todays Date
export const todaysDate = new Date();

// Function to structure date ex : Jun 4, 2011;
export const getDateStructured = (date) => {
  let d = date?.getDate();
  let m = date?.getMonth();
  let y = date?.getFullYear();

  let final = monthNames[m] + " " + d + ", " + y;
  return final;
};

// Function to structure date ex: YYYY-MM-DD
export const setDateForPicker = (rdate) => {
  let d = rdate.getDate();
  d < 10 && (d = "0" + d);
  let m = rdate.getMonth() + 1;
  m < 10 && (m = "0" + m);
  let y = rdate.getFullYear();
  rdate = y + "-" + m + "-" + d;

  return rdate;
};

//current Time
export const currentTime = () => {
  var hours = todaysDate.getHours();
  var minutes = todaysDate.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const calcTime = (date) => {
  var hours = date?.getHours();
  var minutes = date?.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

//Percentage calculation
export const calcPercentage = (str1, str2) => {
  let result = Number(str2) / Number(str1);
  result = result * 100;
  return Math.floor(result);
};

//shortens a long string
export const truncate = (str, n) => {
  return str.length > n
    ? str.substr(0, n - 1) + " " + truncate(str.substr(n - 1, str.length), n)
    : str;
};

export const RedirectAs404 = ({ location }) => (
  <Navigate to={Object.assign({}, location, { state: { is404: true } })} />
);

// returns upload url
export const getUploadParams = () => {
  return { url: "https://httpbin.org/post" };
};

// Converts KB to MB
export const bytesToMegaBytes = (bytes) => {
  let result = bytes / (1024 * 1024);
  return result.toFixed(2);
};

export const bulkActionOptions = [
  { value: "Active", label: "Active" },
  { value: "InActive", label: "InActive" },
];

export const axiosPrivate = Axios.create({
  baseURL: serverAPI,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});
export const formatDateTodays = (date) => {
  const diff = Moment().diff(date, true);
 const x= Moment(new Date(date)).fromNow().includes("Inv")?"Just Now":Moment(new Date(date)).fromNow();
 return x
};

export const timeAsMilliseconds = ({ minutes = 0, hours = 0, seconds = 0 }) => {
  return (
    (minutes * 60 * 1000) + (hours * 60 * 60 * 1000) + (seconds * 1000)
  );
}

export function getTimeUntil(date, options = { addSuffix: true, roundTime: true }) {
  return options.roundTime ? formatDistanceToNow(date, options) : formatDistanceStrict(date, new Date(), options);
}

export function formatMilliseconds(ms, format = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'], options = { trimDays: false }) {
  if (!ms) return 'None';

  const duration = intervalToDuration({ start: 0, end: ms });

  if (options.trimDays) {
    // Convert days to hours
    if (duration.days) {
      duration.hours = (duration.hours || 0) + duration.days * 24;
      delete duration.days;
    }
  }

  return formatDuration(duration, {
    format,
    zero: false,
    delimiter: ', '
  });
}

export const safeParseNumber = (value) => {
  if (typeof value === 'number') return value;
  if (typeof value !== 'string') return 0;
  return Number(value.trim().replaceAll(',', '')) || 0;
};

/**
 * Checks if a value is empty (null, undefined, or empty string)
 * @param {any} value 
 * @returns {boolean}
 */
export const isEmpty = (value) => (value === null || value === undefined || value === '');

/**
 * Checks if an object is empty (has no own properties)
 * @param {Record<string, any>} obj 
 * @returns {boolean}
 */
export const isEmptyObject = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;

/**
 * Checks if a value is an array and is empty
 * @param {any} arr 
 * @returns {boolean}
 */
export const isEmptyArray = (arr) => Array.isArray(arr) && arr.length === 0;

/**
 * Checks if a value is not empty (null, undefined, or empty string), not an empty object, and not an empty array
 * @param {any} value 
 * @returns {boolean}
 */
export const hasValue = (value) => !isEmpty(value) && !isEmptyObject(value) && !isEmptyArray(value);
