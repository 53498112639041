import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import { ModalBody, Col, Spinner } from 'reactstrap';
import { Icon, Button, RSelect, Row } from '../../../components/Component';
import { getDateStructured } from '../../../utils/Utils';
import { useForm } from 'react-hook-form';
import { ColorOptions } from '../../../components/partials/color-select-menu/ColorMenu';
import { tagSet, teamList, themes } from './KanbanData';
import {
  ActivityTypesTask,
  LeadStatus,
  newRoles,
  activeStatus,
  Languages,
  ActivityStatus,
  HttpStatus
} from '../../../utils/envConfig';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { UpdateUser, fetchAgents } from '../../../redux/actions/user';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { showToast } from '../../../utils/toast/toast';
import { useNavigate } from 'react-router-dom';
import SelectLeadSource from '../../../components/SelectLeadSource';

export const KanbanTaskForm = ({ toggle, data, setData, taskToBoard, editTask }) => {
  const [startTime, setStartTime] = useState(new Date());

  const [formData, setFormData] = useState({
    title: editTask ? editTask.title : '',
    desc: editTask ? editTask.desc : '',
    category: editTask ? editTask.meta.category : '',
    date: new Date(),
    due: '',
    board: null,
    tags: editTask ? editTask.meta.tags : [tagSet[0]],
    users: editTask ? editTask.meta.users : [teamList[0]]
  });

  let boardOptions = [];

  Object.keys(data.columns).map((option) => {
    boardOptions = [
      ...boardOptions,
      {
        value: data.columns[option].text,
        label: data.columns[option].text,
        id: data.columns[option].id
      }
    ];
  });

  const submitForm = (returnVal) => {
    let board = taskToBoard
      ? taskToBoard
      : formData.board === null
      ? boardOptions[0]
      : formData.board;
    if (editTask) {
      let defaultTask = {
        task: {
          ...data.task,
          [editTask.id]: {
            id: editTask.id,
            title: formData.title,
            desc: formData.desc,
            meta: {
              users: formData.users,
              tags: formData.tags,
              date: getDateStructured(formData.date),
              category: formData.category
            }
          }
        }
      };
      setData({ ...data, task: defaultTask.task });
    } else {
      let defaultTask = {
        task: {
          ...data.task,
          [`task-newTask`]: {
            id: `task-newTask`,
            title: formData.title,
            desc: formData.desc,
            meta: {
              users: formData.users,
              tags: formData.tags,
              date: getDateStructured(formData.date),
              category: formData.category
            }
          }
        }
      };

      let defaultColumns = {
        columns: {
          ...data.columns,
          [board.id]: {
            ...data.columns[board.id],
            tasks: [...data.columns[board.id].tasks, `task-newTask`]
          }
        }
      };

      setData({
        columnOrder: data.columnOrder,
        task: defaultTask.task,
        columns: defaultColumns.columns
      });
    }

    toggle();
  };

  const deleteTask = () => {
    let defaultData = data;
    delete defaultData.task[editTask.id];

    defaultData.columns[taskToBoard.id].tasks = defaultData.columns[taskToBoard.id].tasks.filter(
      (item) => item !== editTask.id
    );

    setData({ ...defaultData });
  };

  const { errors, register, handleSubmit } = useForm();
  return (
    <ModalBody>
      <a
        href="#cancel"
        onClick={(ev) => {
          ev.preventDefault();
          toggle();
        }}
        className="close"
      >
        <Icon name="cross-sm"></Icon>
      </a>
      <div className="p-2">
        <h5 className="title">{editTask ? 'Update' : 'Add'} Task</h5>
        <div className="mt-4">
          <form className="row gy-4" onSubmit={handleSubmit(submitForm)}>
            <Col sm="6">
              <div className="form-group">
                <label className="form-label">Client Name</label>
                <input
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      title: e.target.value
                    })
                  }
                  className="form-control"
                  ref={register({ required: 'This field is required' })}
                />
                {errors.title && <span className="invalid">{errors.title.message}</span>}
              </div>
            </Col>
            <Col sm="6">
              <div className="form-group">
                <label className="form-label">Task Type</label>
                <RSelect
                  options={tagSet}
                  isMulti
                  defaultValue={formData.tags}
                  onChange={(e) => setFormData({ ...formData, tags: e })}
                />
              </div>
            </Col>

            <Col sm="6">
              <div className="form-group">
                <label className="form-label">Date</label>
                <DatePicker
                  selected={formData.date}
                  onChange={(date) => setFormData({ ...formData, date: date })}
                  className="form-control date-picker"
                />
              </div>
            </Col>
            <Col sm="6">
              <div className="form-group">
                <label className="form-label">Time</label>
                <div className="form-control-wrap has-timepicker focused">
                  <DatePicker
                    selected={startTime}
                    onChange={(date) => setStartTime(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    className="form-control date-picker"
                  />
                </div>
              </div>
            </Col>
            <Col sm="6">
              <div className="form-group">
                <label className="form-label">Property</label>
                <RSelect
                  options={teamList}
                  isMulti
                  defaultValue={formData.users}
                  onChange={(e) => setFormData({ ...formData, users: e })}
                />
              </div>
            </Col>
            <Col className="col-12">
              <div className="form-group">
                <label className="form-label">Description</label>
                <textarea
                  name="desc"
                  value={formData.desc}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      desc: e.target.value
                    })
                  }
                  className="form-control no-resize"
                  ref={register({ required: 'This field is required' })}
                />
                {errors.desc && <span className="invalid">{errors.desc.message}</span>}
              </div>
            </Col>
            <Col className="col-12">
              <ul className="d-flex justify-content-between mt-3">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button color="primary" size="md" type="submit">
                      {editTask ? 'Update' : 'Add'} Task
                    </Button>
                  </li>
                  <li>
                    <Button
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggle();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </Button>
                  </li>
                </ul>
                {editTask && (
                  <ul>
                    <li>
                      <Button color="danger" size="md" onClick={() => deleteTask()}>
                        Delete Task
                      </Button>
                    </li>
                  </ul>
                )}
              </ul>
            </Col>
          </form>
        </div>
      </div>
    </ModalBody>
  );
};

export const KanbanBoardForm = ({ toggle, data, setData, editBoard }) => {
  const [formData, setFormData] = useState({
    title: editBoard ? editBoard.text : '',
    color: editBoard ? themes.find((item) => item.value === editBoard.theme) : themes[0]
  });

  const submitForm = (returnVal) => {
    if (editBoard) {
      let defaultVal = {
        ...data,
        columns: {
          ...data.columns,
          [editBoard.id]: {
            id: editBoard.id,
            text: formData.title === '' ? editBoard.text : returnVal.title,
            theme: formData.color.value,
            tasks: editBoard.tasks
          }
        }
      };
      setData(defaultVal);
    } else {
      let defaultVal = {
        ...data,
        columns: {
          ...data.columns,
          ['column-' + returnVal.title]: {
            id: 'column-' + returnVal.title,
            text: returnVal.title,
            theme: formData.color.value,
            tasks: []
          }
        },
        columnOrder: [...data.columnOrder, `column-${returnVal.title}`]
      };
      setData(defaultVal);
      let container = document.getElementById('kanban-container');
      container.scrollTo(container.offsetWidth, 0);
    }
    toggle();
  };

  const { errors, register, handleSubmit } = useForm();
  return (
    <ModalBody>
      <a
        href="#cancel"
        onClick={(ev) => {
          ev.preventDefault();
          toggle();
        }}
        className="close"
      >
        <Icon name="cross-sm"></Icon>
      </a>
      <div className="p-2">
        <h5 className="title">{editBoard ? 'Update' : 'Add'} Board</h5>
        <div className="mt-4">
          <form className="row gy-4" onSubmit={handleSubmit(submitForm)}>
            <Col className="col-12">
              <div className="form-group">
                <label className="form-label">Board Title</label>
                <input
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      title: e.target.value
                    })
                  }
                  className="form-control"
                  ref={register({ required: 'This field is required' })}
                />
                {errors.title && <span className="invalid">{errors.title.message}</span>}
              </div>
            </Col>
            <Col className="col-12">
              <div className="form-group">
                <label className="form-label">Select Color</label>
                <div className="form-control-select">
                  <RSelect
                    className="react-select-container"
                    classNamePrefix="react-select"
                    formatOptionLabel={ColorOptions}
                    defaultValue={formData.color}
                    options={themes}
                    onChange={(e) => {
                      setFormData({ ...formData, color: e });
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col className="col-12">
              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                <li>
                  <Button color="primary" size="md" type="submit">
                    {editBoard ? 'Update' : 'Add'} Board
                  </Button>
                </li>
                <li>
                  <Button
                    onClick={(ev) => {
                      ev.preventDefault();
                      toggle();
                    }}
                    className="link link-light"
                  >
                    Cancel
                  </Button>
                </li>
              </ul>
            </Col>
          </form>
        </div>
      </div>
    </ModalBody>
  );
};

export const NewKanbanTaskForm = ({
  toggle,
  data,
  setData = null,
  taskToBoard,
  user,
  taskOption,
  callback,
  pendingCompletion,
  setPendingCompletion,
  updatedTaskNotes,
  refreshCallback
}) => {
  const axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [typeOptions, setTypeOptions] = useState(null);
  const [selectedTypeOptions, setSelectedTypeOptions] = useState(null);
  const [propertyId, setPropertyId] = useState(undefined);
  const [offPlanId, setOffPlanId] = useState(undefined);
  const [propertyType, setPropertyType] = useState(undefined);
  const [properties, setProperties] = useState(null);
  const [isPropertiesLoading, setIsPropertiesLoading] = useState(false);
  const [propOptions, setPropOptions] = useState([]);
  const [selectedPropOptions, setSelectedPropOptions] = useState(null);
  const [clientOptions, setClientOptions] = useState(null);
  const [selectedClientOptions, setSelectedClientOptions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const oneYearFromNow = new Date();
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

  const [formData, setFormData] = useState({
    user: user ? user : undefined,
    date: startDate,
    property_list_id: propertyType === 'Property List' ? propertyId : undefined,
    offplan_id: propertyType === 'Off Plan' ? offPlanId : undefined,
    propertyType,
    notes: user ? user?.notes : ''
  });

  useEffect(() => {
    let opt = [];
    ActivityTypesTask &&
      Object.values(ActivityTypesTask).map((type) =>
        opt.push({
          value: type,
          label: type
        })
      );
    setTypeOptions(opt);
    const fetchPropertiesAdmin = async () => {
      setIsPropertiesLoading(true);
      try {
        const res = await axios.get(`/propList`);
        const result = res.data;
        setProperties(result);
      } catch (error) {
        console.log(error);
      } finally {
        setIsPropertiesLoading(true);
      }
    };
    fetchPropertiesAdmin(axios);
    const fetchClientsAdmin = async () => {
      try {
        const res = await axios.get(
          `/search/user/filter?roles[]=${newRoles.User}&per_page=${100}&active=true`
        );
        const result = res.data.findUsersOutput;

        let optProp = [];
        result &&
          Object.values(result).map((type) =>
            optProp.push({
              value: type.id,
              label: `${type.first_name} ${type.last_name}`
            })
          );
        setClientOptions(optProp);
      } catch (error) {
        // console.log( error );
      }
    };
    fetchClientsAdmin(axios);
  }, [axios]);

  useEffect(() => {
    if (properties) {
      let optProp = [];
      properties &&
        Object.values(properties).map((type) =>
          optProp.push({
            value: type.id,
            label: `${type?.referNo} - ${type?.beds} BR - ${
              type?.propertyType?.propertyType ?? ''
            } - ${type?.community?.community}`
          })
        );
      setPropOptions(optProp);
    }
  }, [properties]);

  const submitForm = async (returnVal) => {
    const combinedDateTime = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
      startTime.getHours(),
      startTime.getMinutes()
    );
    // Add 15 minutes
    const minutesToAdd = 15;
    const newDateTime = new Date(combinedDateTime); // Create a new Date object to avoid modifying the original

    newDateTime.setMinutes(newDateTime.getMinutes() + minutesToAdd);

    const data = {
      user_id: user ? user.id : undefined,
      type: selectedTypeOptions,
      date: newDateTime,
      description: formData.notes,
      property_list_id: selectedPropOptions
      //   propertyType === "Property List" ? propertyId : undefined,
      // offplan_id: propertyType === "Off Plan" ? offPlanId : undefined,
      // propertyType,
    };
    // Validate the data
    if (!data.type) {
      // Type is mandatory
      toast.error('Task type is required');
      return;
    } else {
      // Date validation
      const currentDate = new Date();
      const selectedDate = new Date(newDateTime);

      if (selectedDate < currentDate) {
        toast.error('Date should not be in the past');
        return;
      } else {
        if (selectedDate > oneYearFromNow) {
          toast.error('Date should be within one year from now');
          return;
        } else {
        }
      }
    }
    try {
      setIsLoading(true);
      if (!pendingCompletion) {
        // Manual task adding (should be done by Admins)
        const res = await axios.post(`/activities`, data);
        res.status === 200 && toast.success('Created new Task successfully');
      } else {
        // Normal agent workflow, should add a new task before completing
        if (pendingCompletion?.feedback === 'Not Intertested') {
          // client not interested, move lead to Disqualified
          const disqualifyData = {
            lead_status: LeadStatus.Disqualified,
            activity: {
              notes: formData.notes ? formData.notes : undefined
            }
          };

          dispatch(UpdateUser(axios, data?.user_id, disqualifyData));
          toast.warning('Disqualified!');
        } else {
          // client interested, create new task and complete the previous
          await axios.patch(`/activities/${pendingCompletion?.id}`, {
            status: ActivityStatus.Complete,
            notes: updatedTaskNotes
          });
          const res = await axios.post(`/activities`, data);
          res.status === HttpStatus.CREATED && toast.success('Created new Task successfully');
          toast.success('Completed the pending task successfully');
        }
      }

      setPendingCompletion(null); // Clear pending completion
      if (callback && typeof callback === 'function') {
        await callback();
      }
      toggle();
    } catch (err) {
      toast.error('An error occurred.');
      console.log(err);
    } finally {
      setIsLoading(false);
      if (refreshCallback && typeof refreshCallback === 'function') {
        await refreshCallback();
      }
    }
  };

  const { errors, register, handleSubmit } = useForm();
  return (
    <ModalBody>
      <a
        href="#cancel"
        onClick={(ev) => {
          ev.preventDefault();
          setPendingCompletion(null);
          toggle();
        }}
        className="close"
      >
        <Icon name="cross-sm"></Icon>
      </a>
      <div className="p-2">
        <h5 className="title">{taskOption} Task</h5>
        <div className="mt-4">
          <form className="row gy-4" onSubmit={handleSubmit(submitForm)}>
            <Col sm="6">
              <div className="form-group">
                <label className="form-label">Client Name</label>
                {formData.user ? (
                  <input
                    disabled
                    type="text"
                    name="title"
                    value={`${formData.user?.first_name} ${formData.user?.last_name}`}
                    className="form-control"
                    ref={register({ required: 'This field is required' })}
                  />
                ) : (
                  <RSelect
                    options={clientOptions}
                    defaultValue={selectedClientOptions}
                    onChange={(e) => setSelectedClientOptions(e.value)}
                  />
                )}
                {errors.title && <span className="invalid">{errors.title.message}</span>}
              </div>
            </Col>
            <Col sm="6">
              <div className="form-group">
                <label className="form-label">Task Type</label>
                <RSelect
                  options={typeOptions}
                  defaultValue={selectedTypeOptions}
                  onChange={(e) => setSelectedTypeOptions(e.value)}
                />
              </div>
            </Col>

            <Col sm="6">
              <div className="form-group">
                <label className="form-label">Date</label>
                <DatePicker
                  minDate={new Date()}
                  maxDate={oneYearFromNow}
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="form-control date-picker"
                />
              </div>
            </Col>
            <Col sm="6">
              <div className="form-group">
                <label className="form-label">Time</label>
                <div className="form-control-wrap has-timepicker focused">
                  <DatePicker
                    selected={startTime}
                    onChange={(date) => setStartTime(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    className="form-control date-picker"
                  />
                </div>
              </div>
            </Col>
            <Col sm="12">
              <div className="form-group">
                <label className="form-label">Property</label>
                {/* <RSelect
                    options={teamList}
                    isMulti
                    defaultValue={formData.users}
                    onChange={(e) => setFormData({ ...formData, users: e })}
                  /> */}
                <RSelect
                  options={propOptions}
                  isLoading={isPropertiesLoading}
                  loadingMessage={() => <Spinner />}
                  defaultValue={selectedPropOptions}
                  onChange={(e) => setSelectedPropOptions(e.value)}
                />
              </div>
            </Col>
            <Col className="col-12">
              <div className="form-group">
                <label className="form-label">Feedback</label>
                <textarea
                  name="notes"
                  value={formData.notes}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      notes: e.target.value
                    })
                  }
                  className="form-control no-resize"
                  ref={register({ required: 'This field is required' })}
                />
                {/* <textarea
                  name="desc"
                  value={description}
                  onChange={(e) => setDescription(e)}
                  className="form-control no-resize"
                  ref={register({ required: "This field is required" })}
                /> */}
                {errors.desc && <span className="invalid">{errors.desc.message}</span>}
              </div>
            </Col>
            <Col className="col-12">
              <ul className="d-flex justify-content-end mt-3">
                <ul className="align-end gx-2 gy-2">
                  <li>
                    <Button color="primary" size="md" type="submit" disabled={isLoading}>
                      {isLoading ? <Spinner size={'sm'} /> : `${taskOption} Task`}
                    </Button>
                  </li>
                  <li>
                    <Button
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggle();
                      }}
                      className="bg-secondary text-white"
                    >
                      Cancel
                    </Button>
                  </li>
                </ul>
                {/* {editTask && (
                  <ul>
                    <li>
                      <Button
                        color="danger"
                        size="md"
                        onClick={() => deleteTask()}
                      >
                        Delete Task
                      </Button>
                    </li>
                  </ul>
                )} */}
              </ul>
            </Col>
          </form>
        </div>
      </div>
    </ModalBody>
  );
};

export const DisQualifyForm = ({
  toggle,
  data,
  setData = null,
  taskToBoard,
  refreshCallback,
  user
}) => {
  const dispatch = useDispatch();
  const axios = useAxiosPrivate();
  const [formData, setFormData] = useState({
    notes: user ? user?.notes : '',
    user: user ? user?.id : ''
  });
  const [saveLoading, setSaveLoading] = useState(false);

  const submitForm = (returnVal) => {
    setSaveLoading(true);
    const data = {
      lead_status: LeadStatus.Disqualified,
      activity: {
        notes: formData.notes ? formData.notes : undefined
      }
    };

    dispatch(UpdateUser(axios, formData.user, data));
    toast.success('User disqualified successfully!');
    setSaveLoading(false);
    toggle();
    if (refreshCallback && typeof refreshCallback === 'function') {
      refreshCallback();
    }
  };

  const { errors, register } = useForm();
  return (
    <ModalBody>
      <a
        href="#cancel"
        onClick={(ev) => {
          ev.preventDefault();
          toggle();
        }}
        className="close"
      >
        <Icon name="cross-sm"></Icon>
      </a>
      <div className="p-2">
        <h5 className="title">Disqualify Lead</h5>
        <div className="mt-4">
          <Col className="col-12">
            <div className="form-group">
              <label className="form-label">Feedback</label>
              <textarea
                name="notes"
                value={formData.notes}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    notes: e.target.value
                  })
                }
                className="form-control no-resize"
                ref={register({ required: 'This field is required' })}
              />
              {errors.desc && <span className="invalid">{errors.desc.message}</span>}
            </div>
          </Col>
          <Col size="12" className="mt-3">
            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 justify-content-end">
              <li>
                <Button
                  color="primary"
                  size="lg"
                  onClick={(e) => submitForm(e)}
                  disabled={saveLoading}
                >
                  {saveLoading ? 'Disqualifying...' : 'Disqualify'}
                </Button>
              </li>
            </ul>
          </Col>
        </div>
      </div>
    </ModalBody>
  );
};

export const LostForm = ({ toggle, data, setData = null, taskToBoard, user }) => {
  const dispatch = useDispatch();
  const axios = useAxiosPrivate();
  const [formData, setFormData] = useState({
    notes: user ? user?.notes : '',
    user: user ? user?.id : ''
  });

  const submitForm = (returnVal) => {
    const data = {
      lead_status: LeadStatus.Lost,
      activity: {
        notes: formData.notes ? formData.notes : undefined
      }
    };
    dispatch(UpdateUser(axios, formData.user, data));
    toast.success('Lead status changed to lost successfully!');
    toggle();
  };

  const { errors, register, handleSubmit } = useForm();
  return (
    <ModalBody>
      <a
        href="#cancel"
        onClick={(ev) => {
          ev.preventDefault();
          toggle();
        }}
        className="close"
      >
        <Icon name="cross-sm"></Icon>
      </a>
      <div className="p-2">
        <h5 className="title">Lost Lead</h5>
        <div className="mt-4">
          <form className="row gy-4" onSubmit={handleSubmit(submitForm)}>
            <Col className="col-12">
              <div className="form-group">
                <label className="form-label">Feedback</label>
                <textarea
                  name="notes"
                  value={formData.notes}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      notes: e.target.value
                    })
                  }
                  className="form-control no-resize"
                  ref={register({ required: 'This field is required' })}
                />
                {errors.desc && <span className="invalid">{errors.desc.message}</span>}
              </div>
            </Col>
            <Col className="col-12">
              <ul className="d-flex justify-content-between mt-3">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button color="primary" size="md" type="submit">
                      {user ? 'Lost' : ''}
                    </Button>
                  </li>
                  <li>
                    <Button
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggle();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </Button>
                  </li>
                </ul>
              </ul>
            </Col>
          </form>
        </div>
      </div>
    </ModalBody>
  );
};

export const AssignAgent = ({
  toggle,
  data,
  setData = null,
  taskToBoard,
  user,
  callback = (data) => {}
}) => {
  const [optAgents, setOptAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedLeadSource, setSelectedLeadSource] = useState(null);
  const [agentGet, setAgent] = useState(undefined);
  const [saveLoading, setSaveLoading] = useState(false);
  const [reAssignmentReason, setReAssignmentReason] = useState('');
  const axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!agent || (Array.isArray(agent) && !agent.length)) {
      dispatch(fetchAgents(axios));
    }
    // getUserAgent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const agent = useSelector((state) => state.user?.agents);

  // const getUserAgent = async (e) => {
  //   try {
  //     const res = await axios.get(`/usersAgents/user/${user.id}`, { headers });
  //     const tempAgent = res.data?.agent;
  //     setAgent({
  //       value: tempAgent.id,
  //       label: `${tempAgent.user?.first_name} ${tempAgent.user?.last_name}`,
  //     });
  //   } catch (error) {
  //     console.log(error);
  //     if (error.response.status === HttpStatus.NOT_FOUND) {
  //       return;
  //     }
  //     const serverErrorMessage = error.response?.data?.message;
  //     if (serverErrorMessage) {
  //       showToast(serverErrorMessage, 'error');
  //     } else {
  //       showToast('An error occurred while fetching user agents', 'error');
  //     }
  //   }
  // };

  useEffect(() => {
    let options = [];
    let result =
      agent &&
      agent?.length > 0 &&
      agent
        .filter((agents) => agents?.user?.active === true)
        .map((a) =>
          options.push({
            value: a?.id,
            label: a?.user?.first_name + ' ' + a?.user?.last_name
          })
        );
    setOptAgents(options);
  }, [agent, selectedAgent]);

  if (!selectedAgent) {
    setSelectedAgent(optAgents.length > 0 ? optAgents[0].value : null);
  }

  const validate = (selectedAgent, selectedUsers) => {
    if (!selectedAgent.length || selectedAgent === 'Select') {
      toast.error('Select Agent');
      return false;
    } else if (!reAssignmentReason.trim()) {
      toast.error('Please provide a reason for reassignment.');
      return false;
    } else {
      return true;
    }
  };
  const authuser = useSelector((state) => state?.user?.loggedInUser);
  const { access_token } = authuser || '';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${access_token}`
  };

  const addAgentToUser = async (e) => {
    setSaveLoading(false);
    e.preventDefault();
    if (validate(selectedAgent, selectedUsers)) {
      const formData = {
        agent_id: selectedAgent.toString(),
        user_ids: [user.id],
        notes: reAssignmentReason,
        leadSource: selectedLeadSource?.value,
        leadSourceName: selectedLeadSource?.label,
      };
      try {
        setSaveLoading(true);
        const _res = await axios.post('/usersAgents/assign', formData, {
          headers
        });
        // const res = await axios.patch(`/usersAgents/${user.id}`, formData, {
        //   headers,
        // });
        callback(_res?.data);
        toast.success('Assigned Agent Successfully');
        setSaveLoading(false);
        toggle();
      } catch (err) {
        setSaveLoading(false);
        if (axios.isAxiosError(err)) toast.error(err.response.data.message);
        else toast.error('Server error. Try Again !');
      } finally {
        // navigate(0);
      }
    }

    toggle();
  };

  return (
    <ModalBody>
      <a
        href="#cancel"
        onClick={(ev) => {
          ev.preventDefault();
          toggle();
        }}
        className="close"
      >
        <Icon name="cross-sm"></Icon>
      </a>
      <div className="p-2">
        <h5 className="title">Assign Agent</h5>
        <div className="mt-4">
          <Row className="gy-4">
            <Col md="12">
              <div className="form-group">
                <label className="form-label" htmlFor="address-county">
                  Agent *
                </label>
                <RSelect
                  options={optAgents}
                  value={selectedAgent.label ? selectedAgent.label : agentGet}
                  placeholder={selectedAgent.label ? selectedAgent.label : 'Select Agent'}
                  onChange={(selectedOption) => {
                    setAgent(selectedOption);
                    setSelectedAgent(selectedOption.value);
                  }}
                />
              </div>
            </Col>
            <Col md="12">
              <div className="form-group">
                <label className="form-label" htmlFor="address-county">
                  Lead Source *
                </label>
                <SelectLeadSource
                  value={selectedLeadSource}
                  onChange={(selectedOption) => {
                    setSelectedLeadSource(selectedOption);
                  }}
                />
              </div>
            </Col>
            <Col md="12">
              <label className="form-label" htmlFor="address-county">
                Reason for the reassignment *
              </label>
              <textarea
                className="form-control single-line-textarea"
                placeholder="Write the reason for the reassignment"
                onChange={(e) => setReAssignmentReason(e.target.value)}
              />
            </Col>
            <Col size="12">
              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 justify-content-end">
                <li>
                  <Button
                    color="primary"
                    size="lg"
                    onClick={(e) => addAgentToUser(e)}
                    disabled={saveLoading || !selectedAgent.length || !reAssignmentReason}
                  >
                    {saveLoading ? 'Updating...' : 'Update'}
                  </Button>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </div>
    </ModalBody>
  );
};

export const PersonalInformation = ({
  toggle,
  data,
  setData = null,
  taskToBoard,
  user,
  callback = (data) => {}
}) => {
  const authuser = useSelector((state) => state?.user?.loggedInUser);
  const [saveLoading, setSaveLoading] = useState(false);
  const { access_token } = authuser || '';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${access_token}`
  };
  const axios = useAxiosPrivate();

  const [selectedLang, setSelectedLang] = useState([]);
  const [modalTab, setModalTab] = useState('1');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    active: activeStatus[0],
    role: ''
  });
  const languageOptions = Languages.map((language) => ({
    value: language,
    label: language
  }));
  const [countryOptions, setCountryOptions] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const data = await response.json();
        const formattedOptions = data.map((country) => ({
          value: country.name.common,
          label: country.name.common
        }));
        setCountryOptions(formattedOptions);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
    async function fetchOneUser() {
      try {
        await axios
          .get(`/users/${user.id}`, { headers })
          .then((user) => {
            console.log(user);
            setFormData({
              firstName: user.data?.first_name,
              lastName: user.data?.last_name,
              email: user.data?.email,
              phone: user.data?.phone,
              languages: user.data?.languages,
              lead_source: user.data?.lead_source,
              created_at: user.data?.created_at,
              updated_at: user.data?.updated_at,
              active: user.data?.active ? activeStatus[0] : activeStatus[1],
              role: user.data?.role,
              country: user.data?.country,
              address: user.data?.address,
              city: user.data?.city
            });
            setSelectedLang(user.data?.languages);
          })
          .catch((err) => {
            toast.error('User not available !');
          });
      } catch (err) {
        toast.error('Server error. Try Again !');
      }
    }
    user?.id && fetchOneUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);

  const validateEdit = (formData) => {
    let isError = false;

    if (formData.hasOwnProperty('first_name')) {
      if (!formData.first_name || formData.first_name.length < 2) {
        toast.error('Enter a valid First Name');
        isError = true;
      }
    }

    if (!formData.phone || !formData.phone?.trim()?.replaceAll(' ', '')) {
      isError = true;
      toast.error('Enter valid phone number');

      const isPossiblePhone = isPossiblePhoneNumber(
        formData.phone?.trim()?.replaceAll(' ', ''),
        'AE'
      );

      if (isPossiblePhone) {
        isError = true;
        const isValidNum = isValidPhoneNumber(formData.phone?.trim()?.replaceAll(' ', ''), 'AE');

        if (isValidNum) {
          isError = false;
        }
      } else {
        isError = true;
        toast.error('Phone number is not possible');
      }
    }

    if (!formData.role) {
      toast.error('Select the Role');
      return false;
    }
    // console.log( formData );

    if (isError) {
      toast.error('Invalid fields. Please check your inputs.');
    }

    // console.log( isError );

    return isError ? false : true;
  };

  const submitForm = async (e) => {
    setSaveLoading(false);
    e.preventDefault();

    if (!validateEdit(formData)) {
      return;
    }

    //console.log(formData)
    try {
      setSaveLoading(true);
      const newFormData = {
        ...formData,
        phone: formData?.phone?.trim()?.replaceAll(' ', ''),
        active: formData.active === activeStatus[0]
      };

      const userResponse = await axios.patch(`/users/${user.id}`, newFormData, {
        headers
      });
      toast.success('User modified with changes!');
      // console.log( userResponse );
      callback(userResponse.data);
      setSaveLoading(false);
      toggle();
    } catch (err) {
      setSaveLoading(false);
      if (
        err.response &&
        err.response.data &&
        err.response.data.message.includes('duplicate key')
      ) {
        toast.error('User not added. Email already exists!');
      } else {
        toast.error('User not modified with changes!');
      }
    }
  };

  return (
    <ModalBody>
      <a
        href="#cancel"
        onClick={(ev) => {
          ev.preventDefault();
          toggle();
        }}
        className="close"
      >
        <Icon name="cross-sm"></Icon>
      </a>
      <div className="p-2">
        <h5 className="title">Update Profile</h5>
        <ul className="nk-nav nav nav-tabs">
          <li className="nav-item">
            <a
              className={`nav-link ${modalTab === '1' && 'active'}`}
              onClick={(ev) => {
                ev.preventDefault();
                setModalTab('1');
              }}
              href="#personal"
            >
              Personal
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${modalTab === '2' && 'active'}`}
              onClick={(ev) => {
                ev.preventDefault();
                setModalTab('2');
              }}
              href="#address"
            >
              Address
            </a>
          </li>
        </ul>
        <div className="tab-content">
          <div className={`tab-pane ${modalTab === '1' ? 'active' : ''}`} id="personal">
            <Row className="gy-4">
              <Col md="6">
                <div className="form-group">
                  <label className="form-label" htmlFor="full-name">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="firstName"
                    defaultValue={formData?.firstName}
                    onChange={(e) => setFormData({ ...formData, first_name: e.target.value })}
                    placeholder="Enter first name"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label" htmlFor="display-name">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="lastName"
                    defaultValue={formData?.lastName}
                    onChange={(e) => setFormData({ ...formData, last_name: e.target.value })}
                    placeholder="Enter last name"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="form-group mb-2">
                  <label className="form-label" htmlFor="first-name">
                    Phone{' '}
                  </label>
                  <PhoneInput
                    className="phoneInput"
                    defaultCountry="AE"
                    value={formData?.phone}
                    name="phone"
                    placeholder="Enter Your Phone *"
                    onChange={(value) => setFormData({ ...formData, phone: value })}
                  />
                </div>
              </Col>
              {user && user?.role !== newRoles.Agent && (
                <Col md="6">
                  <label className="form-label" htmlFor="first-name">
                    Languages{' '}
                  </label>
                  <RSelect
                    placeholder="Select Languages"
                    options={languageOptions}
                    isMulti
                    value={selectedLang}
                    onChange={(selectedOptions) => {
                      const formattedLanguages = selectedOptions.map((option) => option.value);
                      setFormData({
                        ...formData,
                        languages: formattedLanguages
                      });
                      setSelectedLang(selectedOptions);
                    }}
                  />
                </Col>
              )}
            </Row>
            <Row>
              <Col size="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 justify-content-end">
                  <li>
                    <Button color="primary" size="lg" onClick={submitForm} disabled={saveLoading}>
                      {saveLoading ? 'Updating...' : 'Update'}
                    </Button>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
          <div className={`tab-pane ${modalTab === '2' ? 'active' : ''}`} id="address">
            <Row className="gy-4">
              <Col md="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="address-l1">
                    Address
                  </label>
                  <input
                    type="text"
                    name="address"
                    defaultValue={formData?.address}
                    onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                    className="form-control"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label" htmlFor="address-l2">
                    City
                  </label>
                  <input
                    type="text"
                    name="city"
                    defaultValue={formData?.city}
                    onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                    className="form-control"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label" htmlFor="address-county">
                    Country
                  </label>
                  <RSelect
                    options={countryOptions}
                    placeholder={formData.country ? formData.country : 'Choose country'}
                    defaultValue={formData.country}
                    onChange={(selectedOption) =>
                      setFormData({
                        ...formData,
                        country: selectedOption?.value || ''
                      })
                    }
                  />
                </div>
              </Col>
              <Col size="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 justify-content-end">
                  <li>
                    <Button color="primary" size="lg" onClick={submitForm} disabled={saveLoading}>
                      {saveLoading ? 'Updating...' : 'Update'}
                    </Button>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </ModalBody>
  );
};
