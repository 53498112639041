import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
  RSelect,
} from "../../../components/Component";
import compressImage from "../../../utils/compression/compress";
import uuid from "react-uuid";
import {
  HttpStatus,
  newRoles,
  uploadManyToS3,
} from "../../../utils/envConfig";
import {
  fetchAgentsList,
  fetchUsers,
} from "../../../redux/actions/user";
import {
  fetchBuildings,
  fetchCommunitiesList,
  fetchPropTypesList,
} from "../../../redux/actions/property";
import { Button, Col, Input, Label, Row, Spinner } from "reactstrap";
import RSelectAsync from "../../../components/select/ReactSelectAsync";
import ErrorModal from "../../../components/modals/ErrorModal";
import { useNavigate } from "react-router-dom";
import BuildingCreationModal from "../components/modals/BuildingCreationModal";
import { showToast } from "../../../utils/toast/toast";
import GeneratePocketListingDataModal from "../components/modals/GeneratePocketListingDataModal";
import { toast } from "react-toastify";
import AddClientsModal from "../components/modals/AddClientsModal";

// we need a regex that specifies that string must not start or end with - or _ and must not contain any other special characters apart from - and _ and must not contain any spaces and we cannot have more than one - or _ in a row
const unitIdRegex = /^[a-zA-Z0-9]+([_-]?[a-zA-Z0-9])*$/;

const propertyTypesOptions = [
  { label: "Building", value: "Building" },
  { label: "Store", value: "Store" },
  { label: "Townhouse", value: "Townhouse" },
  { label: "Warehouse", value: "Warehouse" },
  { label: "Office", value: "Office" },
  { label: "Villa", value: "Villa" },
  { label: "Apartment", value: "Apartment" },
  { label: "Plot", value: "Plot" },
];

const CreatePocketListing = () => {
  const { errors, setError, register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const axios = useAxiosPrivate();
  const navigate = useNavigate();

  const purposeOptions = [
    { label: "Sell", value: "Sell" },
    { label: "Lease", value: "Lease" },
  ];

  // State
  const [buildingOptions, setBuildingOptions] = useState([]);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [communityOptions, setCommunityOptions] = useState([]);
  const [selectedCommunity, setSelectedCommunity] = useState(null);
  const [agentOptions, setAgentOptions] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [selectedPurpose, setSelectedPurpose] = useState(null);
  const [leadsOptions, setLeadsOptions] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null);
  const [unitId, setUnitId] = useState("");
  const [size, setSize] = useState(null);
  const [askingPrice, setAskingPrice] = useState(null);
  const [agencyValuationPrice, setAgencyValuationPrice] =
    useState("");
  const [pocketListNotes, setPocketListNotes] =
    useState(null);
  const [beds, setBeds] = useState(null);
  const [baths, setBaths] = useState(null);
  const [propTypeOptions, setPropTypeOptions] = useState([]);
  const [selectedPropertyType, setSelectedPropertyType] = useState("");
  const [uploadedPhotos, setUploadedPhotos] = useState([]);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [creationLoading, setCreationLoading] = useState(false);
  const [isInitialLeadsLoading, setIsInitialLeadsLoading] =
    useState(true);
  const [
    isLeadSelectedWithFoundAgent,
    setIsLeadSelectedWithFoundAgent,
  ] = useState(false);

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const toggleErrorModal = () => setIsErrorModalOpen(!isErrorModalOpen);

  const [isBuildingModalOpen, setIsBuildingModalOpen] = useState(false);
  const toggleBuildingModal = () =>
    setIsBuildingModalOpen(!isBuildingModalOpen);

  const authUser = useSelector((state) => state?.user?.loggedInUser);
  const authAgent = useSelector((state) => state?.user?.loggedInAgent);

  const isAdmin =
    authUser.role === newRoles.Admin ||
    authUser.role === newRoles.MasterAdmin;

  const validationRules = {
    community: {
      required: "Community is required",
    },
    lead: {
      required: "Lead is required",
    },
    propertyType: {
      required: "Property Type is required",
    },
    askingPrice: {
      required: "Asking Price is required",
    },
  };

  const submitForm = async (data) => {
    if (!selectedCommunity) {
      setError("community", {
        message: "Please select community",
      });
    }
    try {
      setCreationLoading(true);
      // upload images and documents to S3 before making the request
      const imagesToUpload = uploadedPhotos?.length
        ? await Promise.all(
            Array.from(uploadedPhotos).map(async (file) => {
              const compressedFile = await compressImage(file);
              return {
                file: compressedFile,
                fileName: `/pocket-listing/images/${uuid()}-${
                  file?.name
                }`,
                type: file?.type ?? "images/*",
              };
            })
          )
        : null;

      const documentsToUpload = uploadedDocuments?.length
        ? Array.from(uploadedDocuments).map((file) => ({
            file: file,
            fileName: `/pocket-listing/docs/${uuid()}-${file?.name}`,
            type: file?.type ?? "application/pdf",
          }))
        : null;

      if (imagesToUpload) {
        await uploadManyToS3(imagesToUpload, axios);
      }
      if (documentsToUpload) {
        await uploadManyToS3(documentsToUpload, axios);
      }
      data = {
        ...data,
        beds: data.beds,
        askingPrice: parseInt(data.askingPrice),
        agentValutionPrice: parseInt(data.agencyValuationPrice),
        baths: parseInt(data.baths),
        size: parseInt(data.size),
        lead: selectedLead?.value,
        propertyType: selectedPropertyType?.value,
        building: selectedBuilding?.value ?? null,
        community: selectedCommunity?.value,
        purpose: selectedPurpose?.value,
        currentAgent: selectedAgent?.value,
        pocketListNotes: pocketListNotes
      };
      if (!selectedAgent) {
        // not sure if sending null to the API would do so deleting for safety
        delete data.currentAgent;
      }
      // first, check if property card exists and has an assigned agent
      // if so we display an error modal
      const existsPayload = {
        unitId: data.unitId,
        buildingId: data.building,
        communityId: data.community,
      };
    if(existsPayload?.unitId){
      const existsRes = await axios.post(
        `/property-cards/property-exists`,
        existsPayload
      );
      if (existsRes?.data?.status === "exist") {
        setIsErrorModalOpen(true);
        return;
      }
    }

      if (imagesToUpload) {
        data.photos = imagesToUpload?.map((image) => ({
          original: image?.fileName,
        }));
      }
      if (documentsToUpload) {
        data.documents = documentsToUpload?.map((doc) => ({
          type: "Other",
          original: doc?.fileName,
        }));
      }
      const res = await axios.post(
        `/property-cards/pocket-listing`,
        data
      );
      if (res?.status === HttpStatus.CREATED) {
        // if successfully created, link to this lead
        showToast("Successfully created Pocket Listing!", "success");
        navigate("/list-pocket-listing");
      }
    } catch (error) {
      console.log(error);
      const serverErrorMessage = error.response.data.message;
      if (serverErrorMessage) {
        showToast(serverErrorMessage, "error");
      } else {
        showToast(
          "Failed to create Pocket Listing, an error occurred.",
          "error"
        );
      }
    } finally {
      setCreationLoading(false);
    }
  };

  const { allCommunities, communitiesLoading } = useSelector(
    (state) => ({
      allCommunities: state?.property?.communities,
      communitiesLoading: state?.property?.communitiesLoading,
    })
  );
  const { allBuildings, buildingsLoading } = useSelector(
    (state) => ({
      allBuildings: state?.property?.buildings,
      buildingsLoading: state?.property?.buildingsLoading,
    })
  );
  const { allPropertyTypes, propTypesLoading } = useSelector(
    (state) => ({
      allPropertyTypes: state?.property?.propertyTypes,
      propTypesLoading: state?.property?.propTypesLoading,
    })
  );
  const { agentLeads, leadsLoading } = useSelector((state) => ({
    agentLeads: state?.user?.users?.findUsersOutput,
    leadsLoading: state?.user?.leadsLoading,
  }));
  const allAgents = useSelector((state) => state?.user?.agents);

  const buildingCreationCallback = async (
    newBuilding,
    chosenCommunity
  ) => {
    dispatch(fetchBuildings(axios));
    setSelectedBuilding({
      label: newBuilding?.name,
      value: newBuilding?.id,
      ...newBuilding
    });
    if (chosenCommunity) {
      setSelectedCommunity(chosenCommunity);
    }
  };

  const agentLeadsUrl = authAgent
    ? `/search/user/filter?agent_id=${authAgent?.id}&roles[]=User&active=true`
    : selectedAgent
    ? `/search/user/filter?agent_id=${selectedAgent?.value}&roles[]=User&active=true`
    : `/search/user/filter?roles[]=User&active=true`;
  useEffect(() => {
    dispatch(fetchCommunitiesList(axios));
    dispatch(fetchBuildings(axios));
    dispatch(fetchPropTypesList(axios));
    dispatch(fetchAgentsList(axios));
    dispatch(fetchUsers(axios, agentLeadsUrl));
  }, [agentLeadsUrl, axios, dispatch]);

  useEffect(() => {
    if (allAgents && allAgents.length) {
      setAgentOptions(
        allAgents?.map((agent) => ({
          label: `${agent?.user?.first_name} ${
            agent?.user?.last_name ?? ""
          }`,
          value: agent?.id ?? agent?._id,
          ...agent,
        }))
      );
    }

    if (allPropertyTypes && allPropertyTypes.length) {
      setPropTypeOptions(
        allPropertyTypes?.map((type) => ({
          label: type?.propertyType,
          value: type?.propertyType,
          ...type,
        }))
      );
    }

    if (allCommunities && Array.isArray(allCommunities)) {
      setCommunityOptions(
        allCommunities?.map((community) => ({
          label: community?.community,
          value: community?.id,
          ...community,
        }))
      );
    }

    if (selectedCommunity) {
      setBuildingOptions(
        allBuildings
          ?.filter(
            (building) =>
              building?.communityId &&
              building?.communityId === selectedCommunity?.value
          )
          ?.map((building) => ({
            label: building?.name,
            value: building?.id,
            ...building,
          }))
      );
    } else {
      setBuildingOptions(
        allBuildings?.map((building) => ({
          label: building?.name,
          value: building?.id,
          ...building,
        }))
      );
    }
  }, [allBuildings, allCommunities, selectedCommunity, allAgents, allPropertyTypes]);

  useEffect(() => {
    if (agentLeads) {
      setLeadsOptions(
        agentLeads?.map((lead) => ({
          ...lead,
          label: `${lead.first_name} ${lead.last_name ?? ""}`,
          value: lead.id,
        }))
      );
      setIsInitialLeadsLoading(false);
    }
  }, [agentLeads, selectedAgent]);

  const fetchLeadsByName = async (inputValue) => {
    try {
      let url = `/search/user/filter?search=${inputValue}&active=true`;
      if (authAgent) {
        url += `&agent_id=${authAgent?.id}`;
      } else if (selectedAgent) {
        url += `&agent_id=${selectedAgent?.value}`;
      }

      const response = await axios.get(url);
      const data = response.data?.findUsersOutput?.map((lead) => ({
        value: lead.id,
        label: `${lead.first_name} ${lead.last_name ?? ""}`,
        ...lead,
      }));
      setLeadsOptions(data);
      return data;
    } catch (error) {
      console.error("Error fetching users:", error);
      return [];
    } finally {
    }
  };

  const handleLeadChange = (newLead) => {
    setSelectedLead(newLead);
    const currentAgent = newLead?.currentAgent;
    if (currentAgent) {
      setSelectedAgent({
        ...currentAgent,
        label: `${currentAgent?.userId?.first_name} ${currentAgent?.userId?.last_name}`,
        value:
          currentAgent?.id ?? currentAgent?._id,
      });
      setIsLeadSelectedWithFoundAgent(true);
    } else {
      setIsLeadSelectedWithFoundAgent(false);
    }
  }

  const [generateModal, setGenerateModal] = useState(false)
  const [generating, setGenerating] = useState(false)

  const handleGenerate=async(text)=>{
    try {
      setGenerating(true)
      if(!text?.trim())
       return toast.error("Please Enter Detailed Text")
      const {data}=await axios.post('/property-cards/pocket-listing/generate-data',{text})
      const result=data?.suggestion
       setBeds(result?.beds)
       setBaths(result?.baths)
       setSize(result?.size)
       setUnitId(result?.unitId)
       setAskingPrice(result?.askingPrice)
       setSelectedPurpose(purposeOptions?.find(x=>{
        const rgx=new RegExp(x?.label,'i')
        return rgx.test(result?.purpose)
       }))
       setSelectedBuilding(buildingOptions?.find(x=>{
        return x?.label?.toLowerCase()?.includes(result?.building?.toLowerCase())
       }))
       setSelectedCommunity(communityOptions?.find(x=>{
        const rgx=new RegExp(x?.label,'i')
        return rgx.test(result?.community)
       }))
       setSelectedPropertyType(propTypeOptions?.find(x=>{
        return x?.label?.toLowerCase()?.includes(result?.propertyType?.toLowerCase())
       }))
       toast.success("Data Generated Successfully \n Check For Validity")
       setGenerateModal(false)
    } catch (error) {
      toast.error(error?.response??"An Error Occured \n You Can Try Addin More Details")
    }finally{
      setGenerating(false)
    }
  }
const [addClient, setAddClient] = useState(false)

  return (
    <>
      <Head title="Create Pocket Listing"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page className="mt-4">
                Add New Pocket Listing
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <div className="w-100 d-flex justify-content-end">
            <Button
              className="mb-2"
              onClick={() => setGenerateModal(true)}
              color="primary"
            >
              <Icon name={"activity"} className={"mx-1"} />
              Generate Data
            </Button>
          </div>
          <form className="gy-4 row" onSubmit={handleSubmit(submitForm)}>
            <PreviewCard>
              <Row>
                <Col sm="12">
                  <div className="form-group">
                    <label className="form-label">
                      Property Type <span className="text-danger">*</span>
                    </label>
                    <RSelect
                      placeholder="Select Property Type..."
                      options={propTypeOptions}
                      isLoading={propTypesLoading}
                      loadingMessage={() => <Spinner size={"sm"} />}
                      value={selectedPropertyType}
                      onChange={(e) => {
                        setSelectedPropertyType(e);
                      }}
                    />
                  </div>
                </Col>
                {isAdmin || !authAgent ? (
                  <Col sm="12" className="mt-3">
                    <div className="form-group">
                      <label className="form-label">
                        Assigned Agent <span className="text-danger">*</span>
                      </label>
                      <div
                        className="d-flex justify-content-between"
                        style={{ gap: "0.5rem" }}
                      >
                        <div className="flex-fill">
                          <RSelect
                            placeholder="Select Assigned Agent..."
                            value={selectedAgent}
                            options={agentOptions}
                            isDisabled={isLeadSelectedWithFoundAgent}
                            onChange={(e) => {
                              setSelectedAgent(e);
                            }}
                          />
                        </div>
                        {isLeadSelectedWithFoundAgent ? (
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              setSelectedAgent(null);
                              setIsLeadSelectedWithFoundAgent(false);
                              setSelectedLead(null);
                            }}
                          >
                            <Icon name={"cross"} />
                          </Button>
                        ) : null}
                      </div>
                    </div>
                  </Col>
                ) : null}
                <Col sm="6" className="mt-3">
                  <div className="form-group">
                    <label className="form-label">
                      Lead <span className="text-danger">*</span>
                    </label>
                    <div className="d-flex">
                      <div className="w-100 me-1">
                        <RSelectAsync
                          placeholder={"Search by Name or Phone Number"}
                          value={selectedLead}
                          defaultOptions={leadsOptions}
                          cacheOptions={true}
                          isDisabled={leadsLoading}
                          isLoading={isInitialLeadsLoading}
                          loadOptionsFn={fetchLeadsByName}
                          onChange={(lead) => handleLeadChange(lead)}
                        />
                      </div>
                      <Button
                        onClick={() => setAddClient(true)}
                        type="button"
                        color="secondary"
                      >
                        <Icon name={"plus"} />
                      </Button>
                    </div>
                  </div>
                </Col>
                <Col sm="6" className="mt-3">
                  <div className="form-group">
                    <label className="form-label">
                      Purpose <span className="text-danger">*</span>
                    </label>
                    <RSelect
                      value={selectedPurpose}
                      options={purposeOptions}
                      onChange={(e) => setSelectedPurpose(e)}
                      placeholder="Select Purpose..."
                    />
                  </div>
                </Col>
                <Col sm="6" className="mt-3">
                  <div className="form-group">
                    <label className="form-label">
                      Community <span className="text-danger">*</span>
                    </label>
                    <RSelect
                      options={communityOptions}
                      placeholder="Select Community..."
                      value={selectedCommunity}
                      isLoading={communitiesLoading}
                      loadingMessage={() => <Spinner size={"sm"} />}
                      onChange={(e) => {
                        setSelectedCommunity(e);
                        // clear selected building if a different community is selected
                        if (
                          selectedBuilding &&
                          selectedBuilding?.communityId?.toString() !==
                            e?.value?.toString()
                        ) {
                          setSelectedBuilding(null);
                        }
                      }}
                    />
                    {errors.community && (
                      <small className="text-danger invalid">
                        {errors.community.message}
                      </small>
                    )}
                  </div>
                </Col>
                <Col sm="6" className="mt-3">
                  <div className="form-group">
                    <label className="form-label">Building</label>
                    <div
                      className="d-flex justify-content-between"
                      style={{ gap: "0.5rem" }}
                    >
                      <div className="flex-fill">
                        <RSelect
                          options={buildingOptions}
                          placeholder="Select Building..."
                          value={selectedBuilding}
                          isLoading={buildingsLoading}
                          loadingMessage={() => <Spinner size={"sm"} />}
                          onChange={(e) => {
                            setSelectedBuilding(e);
                            // Autofill community filter with the building's community info
                            if (
                              e.communityId &&
                              Object.keys(e.community).length
                            ) {
                              setSelectedCommunity({
                                label: e.community?.community,
                                value: e.community?.id ?? e.community?._id,
                                ...e.community,
                              });
                            }
                          }}
                        />
                      </div>
                      <Button className="" onClick={toggleBuildingModal}>
                        <Icon name={"plus"} />
                      </Button>
                    </div>
                  </div>
                </Col>
                <Col sm="3" className="mt-3">
                  <div className="form-group">
                    <label className="form-label">Unit Number</label>
                    <Input
                      name="unitId"
                      placeholder="Unit Number"
                      value={unitId}
                      onChange={(e) => {
                        setUnitId(e.target.value);
                      }}
                      innerRef={register(validationRules.unitId)}
                    />
                    {errors.unitId && (
                      <small className="text-danger invalid">
                        {errors.unitId.message}
                      </small>
                    )}
                  </div>
                </Col>
                <Col sm="3" className="mt-3">
                  <div className="form-group">
                    <label className="form-label">Size (SQFT.)</label>
                    <Input
                      type="number"
                      className={`${errors?.size && `border border-danger`}`}
                      name="size"
                      placeholder="Size"
                      value={size}
                      onChange={(e) => {
                        setSize(e.target.value);
                      }}
                      innerRef={register()}
                    />
                  </div>
                </Col>
                <Col sm="3" className="mt-3">
                  <div className="form-group">
                    <label className="form-label">Number of Beds</label>
                    <Input
                      type="text"
                      className={`${errors?.beds && `border border-danger`}`}
                      name="beds"
                      placeholder="Beds"
                      value={beds}
                      onChange={(e) => {
                        setBeds(e.target.value);
                      }}
                      innerRef={register()}
                    />
                  </div>
                </Col>
                <Col sm="3" className="mt-3">
                  <div className="form-group">
                    <label className="form-label">Number of Baths</label>
                    <Input
                      type="number"
                      className={`${errors?.baths && `border border-danger`}`}
                      name="baths"
                      placeholder="Baths"
                      value={baths}
                      onChange={(e) => {
                        setBaths(e.target.value);
                      }}
                      innerRef={register()}
                    />
                  </div>
                </Col>
                <Col sm="6" className="mt-4">
                  <div className="form-group">
                    <label className="form-label">
                      Asking Price <span className="text-danger">*</span>
                    </label>
                    <Input
                      type="number"
                      className={`${
                        errors?.askingPrice && `border border-danger`
                      }`}
                      name="askingPrice"
                      placeholder="Asking Price"
                      value={askingPrice}
                      onChange={(e) => {
                        setAskingPrice(e.target.value);
                      }}
                      innerRef={register(validationRules.askingPrice)}
                    />
                    {errors.askingPrice && (
                      <small className="text-danger invalid">
                        {errors.askingPrice.message}
                      </small>
                    )}
                  </div>
                </Col>
                <Col sm="6" className="mt-4">
                  <div className="position-relative form-group">
                    <label className="form-label">Agency Valuation</label>
                    <Input
                      type="number"
                      name="agencyValuationPrice"
                      placeholder="Agency Valuation"
                      value={agencyValuationPrice}
                      onChange={(e) => {
                        setAgencyValuationPrice(e.target.value);
                      }}
                    />
                  </div>
                </Col>
                <Col sm="6" className="mt-4">
                  <Label tag="h5">Photos</Label>
                  <Input
                    type="file"
                    placeholder="Upload Photos..."
                    multiple
                    id="photos"
                    name="photos"
                    accept="image/*"
                    onChange={(e) => {
                      if (!e.target.files || e.target.files.length === 0)
                        return;
                      setUploadedPhotos(e.target.files);
                    }}
                  />
                  {errors.photos && (
                    <small className="text-danger invalid">
                      {errors.photos.message}
                    </small>
                  )}
                </Col>
                <Col sm="6" className="mt-4">
                  <Label tag="h5">Documents</Label>
                  <Input
                    type="file"
                    placeholder="Upload Documents..."
                    multiple
                    id="documents"
                    name="documents"
                    accept="application/pdf"
                    onChange={(e) => {
                      if (!e.target.files || e.target.files.length === 0)
                        return;
                      setUploadedDocuments(e.target.files);
                    }}
                  />
                </Col>
                <Col sm="12" className="mt-4">
                  <div className="form-group">
                    <label className="form-label">Notes</label>
                    <textarea
                      name="notes"
                      className="form-control no-resize"
                      placeholder="Notes"
                      value={pocketListNotes}
                      onChange={(e) => {
                        setPocketListNotes(e.target.value);
                      }}
                    />
                  </div>
                </Col>
                <Col
                  sm="12"
                  className="mt-4"
                  style={{ display: "flex", justifyContent: "end" }}
                >
                  <Button
                    type="submit"
                    color="primary"
                    disabled={creationLoading}
                  >
                    {creationLoading ? (
                      <Spinner size="sm" color="light">
                        Loading....
                      </Spinner>
                    ) : (
                      "Create"
                    )}
                  </Button>
                </Col>
              </Row>
            </PreviewCard>
          </form>
        </Block>
        <ErrorModal
          isOpen={isErrorModalOpen}
          toggleFn={toggleErrorModal}
          header={"Cannot create Pocket Listing"}
        >
          <p className="text-center fs-6" style={{ maxWidth: "40ch" }}>
            The property card you are trying to create already exists.
          </p>
          <p className="text-soft">Please contact administration for help.</p>
          <Button
            className="btn-light btn-mw"
            size="lg"
            onClick={toggleErrorModal}
          >
            OK
          </Button>
        </ErrorModal>
        <BuildingCreationModal
          isOpen={isBuildingModalOpen}
          toggleFn={toggleBuildingModal}
          chosenCommunity={selectedCommunity}
          onBuildingCreated={buildingCreationCallback}
        />
        <GeneratePocketListingDataModal
          isOpen={generateModal}
          loading={generating}
          toggle={() => setGenerateModal(false)}
          onGenerate={(text) => handleGenerate(text)}
        />
        <AddClientsModal
          isOpen={addClient}
          onCreated={(lead) => {
            setSelectedLead((c) => ({
              ...lead,
              label: `${lead.first_name} ${lead.last_name ?? ""}`,
              value: lead.id,
            }));
            if (lead?.currentAgent) {
              setSelectedAgent((c) => ({
                label: `${lead?.currentAgent?.user?.first_name} ${
                  lead?.currentAgent?.user?.last_name ?? ""
                }`,
                value: lead?.currentAgent?.id ?? lead?.currentAgent?._id,
                ...lead?.currentAgent,
              }));
            }
          }}
          toggle={() => setAddClient(false)}
        />
      </Content>
    </>
  );
};

export default CreatePocketListing;