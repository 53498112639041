/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { useMemo, useState } from "react";
import { ViewportInfo } from "../../layout/profile/types";
import ProfileLayout from "../../layout/profile/layout/ProfileLayout";
import ProfileContent from "../../layout/profile/content/ProfileContent";
import ProfileSidebar from "../../layout/profile/sidebar/ProfileSidebar";
import SidebarCard from "../../layout/profile/sidebar/components/SidebarCard";
import { useParams } from "react-router-dom";
import ActivitiesContainer from "../../components/activities-view/ActivitiesContainer";
import PropertiesCardsContainer from "../../components/PropertiesCards-view/PropertiesCardsContainer";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useQuery, useQueryClient } from "react-query";
import usersQueries from "../../queries/usersQueries";
import Head from "../../layout/head/Head";
import "../../api/global-types/types";
import UserPersonalInfoContainer from "../../components/user-personal-info/UserPresonalInfoContainer";
import { newRoles } from "../../utils/envConfig";
import ProfileDealsUserContainer from "../../components/deal-view/ProfileDealsUserContainer";
import ProfileDealsAgentContainer from "../../components/deal-view/ProfileDealsAgentContainer";
import AgentTeamContainer from "../../components/team-view/AgentTeamContainer";
import useMutationQuery from "../../hooks/useMutationQuery";
import { updateUser } from "../../api/user/updateUser";
import { showToast } from "../../utils/toast/toast";
import ProfileHeaderCard from "../../layout/profile/sidebar/components/cards/ProfileHeaderCard";
import NavigationCard from "../../layout/profile/sidebar/components/cards/NavigationCard";
import LeadSourceLog from "../leads/components/LeadSourceLog";

/**
 * @typedef {Object} UserQueryResult
 * @property {User} data
 * @property {boolean} isLoading
 * @property {Error} error
 */

const ONE_HOUR = 1000 * 60 * 60;
const THIRTY_MINUTES = 1000 * 60 * 30;

/**
 * General profile component
 * @returns {JSX.Element} The rendered component.
 */
const Profile = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();

  /**
   * @type {[ViewportInfo, React.Dispatch<React.SetStateAction<ViewportInfo>>]}
   */
  const [viewportInfo, setViewportInfo] = useState({
    isSm: false,
    mobileView: false,
  });

  const memoizedViewportInfo = useMemo(() => viewportInfo, [viewportInfo]);

  const axios = useAxiosPrivate();
  const { queryKey, queryFn } = usersQueries.user(id, axios);
  const { queryKey: agentQueryKey, queryFn: fetchAgentInfo } =
    usersQueries.agent(id, axios);

  /**
   * @type {UserQueryResult}
   */
  const {
    data: user,
    isLoading,
    error,
  } = useQuery(queryKey, queryFn, {
    staleTime: ONE_HOUR,
    refetchOnWindowFocus: false,
    refetchInterval: THIRTY_MINUTES,
    enabled: id != undefined,
  });

  const isAgent = useMemo(
    () =>
      user?.id != undefined && user?.role_duplicate?.includes(newRoles.Agent),
    [user?.id, user?.role_duplicate]
  );

  const {
    data: agent,
    isLoading: isAgentLoading,
    error: agentError,
  } = useQuery(agentQueryKey, fetchAgentInfo, {
    staleTime: ONE_HOUR,
    refetchOnWindowFocus: false,
    refetchInterval: THIRTY_MINUTES,
    enabled: id != undefined && isAgent,
  });

  const userUpdate = useMutationQuery(
    ({ userId, payload }) =>
      updateUser(axios, userId, payload),
    {
      onSettled: () => {
        queryClient.invalidateQueries(queryKey);
      },
      onError: (error) => {
        showToast("Something went wrong, failed to update user.", "error");
        console.log(error);
      },
    }
  );

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading user data: {error.message}</div>;

  return (
    <>
      <ProfileLayout
        user={user}
        isAgent={isAgent}
        userUpdate={userUpdate}
        viewportInfo={memoizedViewportInfo}
        setViewportInfo={setViewportInfo}
      >
        <Head title={`${user?.first_name ?? "User"}'s Profile`}></Head>
        <ProfileSidebar viewportInfo={viewportInfo}>
          <SidebarCard>
            <ProfileHeaderCard 
              user={user} 
              isAgent={isAgent} 
              agent={agent}
            />
          </SidebarCard>
          <SidebarCard className="p-0">
            <NavigationCard isAgent={isAgent} setViewportInfo={setViewportInfo} />
          </SidebarCard>
        </ProfileSidebar>
        <ProfileContent
          viewportInfo={memoizedViewportInfo}
          setViewportInfo={setViewportInfo}
        >
          {!isAgent && <ActivitiesContainer user={user} />}
             <LeadSourceLog lead = {user} />
          {!isAgent && <PropertiesCardsContainer lead={user} />}
       
          {isAgent ? (
            <ProfileDealsAgentContainer agent={agent} />
          ) : (
            <ProfileDealsUserContainer user={user} />
          )}
          <UserPersonalInfoContainer user={user} />
          {isAgent && <AgentTeamContainer agent={agent} />}
        </ProfileContent>
      </ProfileLayout>
    </>
  );
};
export default Profile;
