import { useQuery } from 'react-query';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { timeAsMilliseconds } from '../../../utils/Utils';
import { RSelect } from '../../../components/Component';
import { components } from 'react-select';
import { Badge, Spinner } from 'reactstrap';
import React, { useMemo } from 'react';
import { LeadSourceType } from '../../../utils/envConfig';
import { FixedSizeList as List } from 'react-window';

const OPTION_HEIGHT = 35;
const MenuList = ({ options, children, maxHeight, getValue }) => {
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * OPTION_HEIGHT;
  const height = Math.min(maxHeight, children.length * OPTION_HEIGHT);

  return (
    <List
      height={height}
      itemCount={children.length}
      itemSize={OPTION_HEIGHT}
      initialScrollOffset={initialOffset}
      width="100%"
    >
      {({ index, style }) => (
        <div style={style}>
          {React.cloneElement(children[index], {
            ...children[index].props,
            style: { ...children[index].props.style, ...style }
          })}
        </div>
      )}
    </List>
  );

};

const LeadSourceFilter = ({ stateVar, onChange, className, hotOnly = false }) => {
  const axios = useAxiosPrivate();

  const query = useQuery(
    ['allLeadSources'],
    async () =>
      (
        await axios.get('/leadSources', {
          params: hotOnly ? { limit: 1000, leadSourceType: LeadSourceType.HOT } : { limit: 1000 }
        })
      ).data,
    { refetchOnWindowFocus: false, refetchInterval: timeAsMilliseconds({ hours: 1 }) }
  );

  const options = useMemo(() => 
    query.data?.data?.map((leadSource) => ({
      value: leadSource?.id ?? leadSource?._id,
      label: `${leadSource?.name}`,
      ...leadSource
    })) ?? [],
    [query.data]
  );

  const handleChange = (selectedOption) => {
    onChange(selectedOption);
  };

  return (
    <RSelect
      placeholder="Choose Lead Source"
      options={options}
      components={query.data?.data?.length ? { MenuList, Option, NoOptionsMessage } : { Option, NoOptionsMessage }}
      // components={{ Option, NoOptionsMessage }}
      loadingMessage={() => <Spinner />}
      value={stateVar}
      error={query.error}
      isLoading={query.isLoading}
      onChange={handleChange}
      className={className}
    />
  );
};

const NoOptionsMessage = (props) => {
  const { error } = props.selectProps;

  return error ? (
    <div className="p-2 text-danger">Failed to load lead sources: {error.message}</div>
  ) : (
    <components.NoOptionsMessage {...props}>No options available</components.NoOptionsMessage>
  );
};

const Option = (props) => {
  const isHot = useMemo(() => {
    if (props?.data?.leadSourceType === LeadSourceType.HOT) {
      return true;
    }
    return false;
  }, [props?.data?.leadSourceType]);

  return (
    <components.Option {...props}>
      <div className="d-flex justify-content-between">
        <span>{`${props?.data?.label}`}</span>
        <Badge color={isHot ? 'danger' : 'secondary'}>{`${props?.data?.leadSourceType}`}</Badge>
      </div>
    </components.Option>
  );
};

export default LeadSourceFilter;
