import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useCallback, useRef, useState } from 'react';
import RSelectAsync from '../../../components/select/ReactSelectAsync';
import { debounce } from 'lodash';

const PlaceGeoSearchFilter = ({
  stateVar,
  onChange,
  radius = 2,
  maxResults = null,
  notStyled = false,
  defaultOptions = [],
  isClearable = true,
  isMulti = false,
  ...props
}) => {
  const axios = useAxiosPrivate();

  const [inputValue, setInputValue] = useState('');
  const [hasSearched, setHasSearched] = useState(false);
  const [loading, setLoading] = useState(false);

  const abortControllerRef = useRef(null);
  const lastValidInputRef = useRef('');

  const handleInputChange = (value) => {
    // Cancel any ongoing request on input change
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  
    lastValidInputRef.current = value;
    setInputValue(value);
      
    if (value) {
      setHasSearched(true);
    }
  };

  const noOptionsMessage = ({ inputValue }) => {
    if (!hasSearched) {
      return 'Please type to look for places';
    }
    return inputValue ? 'No matching places found' : 'Please enter a search term';
  };

  const fetchPlaces = async (input) => {
    // Don't proceed if the input doesn't match the last valid input
    if (input !== lastValidInputRef.current) {
      return [];
    }
  
    setLoading(true);
    if (!input) {
      setLoading(false);
      return [];
    }  

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();
    const { signal } = abortControllerRef.current;

    try {
      let url = `/community/geo-search?text=${input}&radius=${radius}`;
      if (maxResults) {
        url += `&limit=${maxResults}`;
      }
      const response = await axios.get(url, { signal });

      // Verify the input is still valid before processing results
      if (input !== lastValidInputRef.current) {
        return [];
      }

      abortControllerRef.current = null;

      return response?.data?.results.map((item) => ({
        ...item,
        value: item?.id,
        label: item?.community
      }));
    } catch (err) {
      if (err?.name === 'CanceledError' || err?.name === 'AbortError') {
        // Ignore canceled requests
        return [];
      }
      console.error('Error fetching places:', err);
      return [];
    } finally {
      if (!abortControllerRef.current) {
        setLoading(false); // Only set loading to false if no other request is pending
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadSuggestions = useCallback(
    debounce((inputValue, callback) => {
      fetchPlaces(inputValue).then((options) => callback(options));
    }, 1000),
    []
  );

  return (
    <RSelectAsync
      loadOptionsFn={loadSuggestions}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={(selected) => {
        onChange(selected);
      }}
      placeholder="Look for a Place"
      className={notStyled ? '' : 'status-div'}
      defaultOptions={defaultOptions}
      value={stateVar}
      isClearable={isClearable}
      isLoading={loading}
      noOptionsMessage={noOptionsMessage}
      cacheOptions
      isMulti={isMulti}
      closeMenuOnSelect={!isMulti}
      {...props}
    />
  );
};

export default PlaceGeoSearchFilter;
