import { useQuery } from 'react-query';
import {
  Button,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import { getAllCommunityTeams } from '../../../../api/community-teams/getAllCommunityTeams';
import { showToast } from '../../../../utils/toast/toast';
import { useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import * as HoverCard from '@radix-ui/react-hover-card';
import { Icon } from '../../../../components/Component';

const ONE_HOUR = 60 * 60 * 1000;

const MetaCommunityFilter = ({
  isOpen,
  toggle,
  onChange,
  onClear,
  selected,
  axiosInstance
}) => {
  const { data, isLoading } = useQuery(
    'metaCommunities',
    () => getAllCommunityTeams(axiosInstance),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: ONE_HOUR,
      refetchInterval: ONE_HOUR,
      onError: (error) => {
        console.log(error);
        showToast('Could not fetch meta communities', 'error', 5000);
      }
    }
  );

  const [selectedMetaCommunities, setSelectedMetaCommunities] = useState(
    selected || []
  );

  useEffect(() => {
    setSelectedMetaCommunities(selected || []);
  }, [selected]);

  const handleCommunityToggle = (community) => {
    setSelectedMetaCommunities((prev) => {
      if (_.some(prev, ['id', community.id])) {
        return _.reject(prev, ['id', community.id]);
      } else {
        return [...prev, community];
      }
    });
  };

  const [showSelected, setShowSelected] = useState(false);
  const selectedRef = useRef(null);

  useEffect(() => {
    if (selectedMetaCommunities.length > 0) {
      setShowSelected(true);
    } else {
      setShowSelected(false);
    }
  }, [selectedMetaCommunities]);

  const communitiesWithPlaces = useMemo(() => data?.results?.filter((community) => community?.communities?.length > 0)?.map((team) => ({ ...team, value: team?.communities})), [data]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle}>
        Choose Master Communities {!isLoading ? `(${communitiesWithPlaces?.length} total)` : null}
      </ModalHeader>
      <div
        ref={selectedRef}
        className={`selected-places px-4 py-3 ${showSelected ? 'show' : ''}`}
        style={{
          opacity: showSelected ? 1 : 0,
          maxHeight: showSelected
            ? `${selectedRef.current?.scrollHeight}px`
            : '0px',
          overflow: 'hidden',
          transition: 'opacity 0.3s ease, max-height 0.3s ease'
        }}
      >
        <span className="fw-medium" style={{ fontSize: '1.002rem' }}>
          Places you've selected:
        </span>
        <ul className="d-flex flex-wrap" style={{ gap: '.5rem' }}>
          {(selectedMetaCommunities ?? [])
            ?.map((community) => community?.communities ?? [])
            ?.flat()
            ?.map((place, idx) => (
              <li key={(place?.id ?? place?._id) + '' + idx}>
                <span className="badge bg-outline-primary">
                  {place?.community}
                </span>
              </li>
            ))}
        </ul>
      </div>
      <ModalBody className='pt-0'>
        <ul className="custom-control-group">
          {communitiesWithPlaces?.map((community) => (
            <HoverCard.Root
              openDelay={300}
              closeDelay={100}
              key={community?.id}
            >
              <HoverCard.Trigger asChild>
                <li>
                  <div className="custom-control custom-checkbox custom-control-pro no-control">
                    <input
                      type="checkbox"
                      disabled={!community?.enabled}
                      checked={_.some(selectedMetaCommunities, [
                        'id',
                        community?.id
                      ])}
                      onChange={(e) => handleCommunityToggle(community)}
                      className="custom-control-input"
                      name={`community-${community?.id}`}
                      id={`community-${community?.id}`}
                    />
                    <label
                      className={`custom-control-label px-4 py-2 ${
                        community?.enabled
                          ? 'text-dark fw-medium'
                          : 'text-muted'
                      }`}
                      htmlFor={`community-${community?.id}`}
                    >
                      {community?.teamName}
                    </label>
                  </div>
                </li>
              </HoverCard.Trigger>
              <HoverCard.Portal>
                <HoverCard.Content className="HoverCardContent">
                  <Container>
                    <span
                      className="fw-medium"
                      style={{ fontSize: '1.002rem' }}
                    >
                      Agents working in this community
                    </span>
                    <ul
                      className="mt-2 d-flex flex-wrap"
                      style={{ gap: '.5rem' }}
                    >
                      {community?.members?.map((agent) => (
                        <li key={agent?.id ?? agent?._id}>
                          <span className="badge bg-outline-gray">
                            {agent?.first_name} {agent?.last_name}
                          </span>
                        </li>
                      ))}
                    </ul>
                    <hr />
                    <span
                      className="fw-medium"
                      style={{ fontSize: '1.002rem' }}
                    >
                      Places in this community
                    </span>
                    <ul
                      className="mt-2 d-flex flex-wrap"
                      style={{ gap: '.5rem' }}
                    >
                      {community?.communities?.map((place, idx) => (
                        <li key={(place?.id ?? place?._id) + '' + idx}>
                          <span className="badge bg-outline-gray">
                            {place?.community}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </Container>
                  <HoverCard.Arrow className="HoverCardArrow" />
                </HoverCard.Content>
              </HoverCard.Portal>
            </HoverCard.Root>
          ))}
        </ul>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <div>
          <span className="text-muted fst-italic d-flex align-items-center" >
            <Icon name="info" />
            <span>
              Hover over a community to see its places and the agents working
              there.
            </span>
          </span>
        </div>
        <div className="d-flex" style={{ gap: '.5rem' }}>
          <Button
            color="primary"
            onClick={() => onChange(selectedMetaCommunities)}
          >
            Apply
          </Button>
          <Button
            color="danger"
            onClick={() => { setSelectedMetaCommunities((_) => []); onClear?.(); }}
          >
            Clear
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default MetaCommunityFilter;
