import { useMemo, useRef, useState } from 'react';
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  Icon,
  PaginationComponent
} from '../../../../../components/Component';
import DocumentPreview from '../../../../deals/components/transactions/components/DocumentPreview';
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner
} from 'reactstrap';
import compressImage from '../../../../../utils/compression/compress';
import uuid from 'react-uuid';
import { deleteFromS3, HttpStatus, uploadManyToS3 } from '../../../../../utils/envConfig';
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { showToast } from '../../../../../utils/toast/toast';
import _ from 'lodash';

const PocketListingDocumentList = ({ propertyCard, isAdminOrAssigned = false, refreshFn }) => {
  const axios = useAxiosPrivate();

  const documents = useMemo(() => propertyCard?.documents ?? [], [propertyCard?.documents]);

  const [pageIndex, setPageIndex] = useState(1);
  const docsPerPage = useRef(2);

  const paginatedDocs = useMemo(() => {
    const start = (pageIndex - 1) * docsPerPage.current;
    return documents.slice(start, start + docsPerPage.current);
  }, [pageIndex, docsPerPage, documents]);

  const [isDocumentsModalOpen, setIsDocumentsModalOpen] = useState(false);
  const toggleDocumentsModal = () => setIsDocumentsModalOpen(!isDocumentsModalOpen);

  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);

  const handleFileUpload = async () => {
    if (uploadedDocuments.length) {
      try {
        const promises = Array.from(uploadedDocuments).map(async (file) => {
          const compressedFile =
            file?.type === 'application/pdf' ? file : await compressImage(file);
          const fileName = `/pocket-listing/docs/${uuid()}-${file?.name}`;
          return {
            file: compressedFile,
            fileName,
            type: file?.type ?? 'images/*'
          };
        });
        const documentsToUpload = await Promise.all(promises);

        if (documentsToUpload && documentsToUpload.length) {
          setUploadLoading(true);
          await uploadManyToS3(documentsToUpload, axios);
        }

        return documentsToUpload.map((file) => file.fileName);
      } catch (uploadError) {
        showToast('Failed to upload documents.', 'error');
        console.log(uploadError);
      } finally {
        setUploadLoading(false);
      }
    }
  };

  const [documentIndicesToDelete, setDocumentIndicesToDelete] = useState([]);
  const handleDeletePhotos = () => {
    const existingDocs = [...documents];

    // Sort indices in descending order to avoid shifting issues
    const sortedIndices = _.sortBy(documentIndicesToDelete, (index) => -index);

    const docsToDelete = [];

    _.forEach(sortedIndices, (index) => {
      if (index >= 0 && index < existingDocs.length) {
        // make sure the index is not out of array bounds
        const [removedDoc] = existingDocs.splice(index, 1);
        docsToDelete.unshift(removedDoc);
      }
    });

    return {
      docsToDelete,
      existingDocs
    };
  };

  const [deleteLoading, setDeleteLoading] = useState(false);
  const deleteDocumentFromStorage = async (docs = []) => {
    try {
      setDeleteLoading(true);
      const promises =
        docs && docs.length ? docs.map(async (doc) => await deleteFromS3(doc, axios)) : [];
      await Promise.all(promises);
    } catch (error) {
      showToast('Failed to delete documents from S3.', 'error');
    } finally {
      setDeleteLoading(false);
    }
  };

  const resetStates = () => {
    setDocumentIndicesToDelete([]);
    setUploadedDocuments([]);
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmitLoading(true);

    const { docsToDelete, existingDocs } = handleDeletePhotos();

    await deleteDocumentFromStorage(docsToDelete);

    const newlyUploadedDocuments = await handleFileUpload();

    const finalDocs = existingDocs ?? [];
    finalDocs.push(...(newlyUploadedDocuments ?? []));

    try {
      const res = await axios.put(`/property-cards/${propertyCard?.id ?? propertyCard?._id}`, {
        documents: finalDocs.map((doc) =>
          typeof doc === 'string'
            ? { original: doc, type: 'Other' }
            : { original: doc?.original, type: 'Other' }
        )
      });
      if (res.status === HttpStatus.OK) {
        showToast('Successfully updated documents', 'success');
      }
    } catch (error) {
      console.log(error);
      showToast('Property card update failed', 'error');
    } finally {
      toggleDocumentsModal();
      refreshFn && typeof refreshFn === 'function' && refreshFn();
      setSubmitLoading(false);
      resetStates();
    }
  };

  return (
    <>
      <BlockHead>
        <BlockBetween>
          <BlockHeadContent>
            <h5 className="nk-block-title title" id="notes">
              Documents
            </h5>
          </BlockHeadContent>
          <BlockHeadContent>
            {isAdminOrAssigned ? (
              <Button color="primary" size="sm" onClick={toggleDocumentsModal}>
                <div className="d-flex align-items-center">
                  <Icon name={documents.length ? 'edit' : 'plus'} />
                  <span className="">
                    {documents.length ? 'Change Documents' : 'Add Documents'}
                  </span>
                </div>
              </Button>
            ) : null}
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      {documents.length ? (
        <div>
          <div className="d-flex flex-wrap" style={{ gap: '1rem' }}>
            {paginatedDocs.map((doc, index) => (
              <DocumentPreview
                key={`doc-preview-${index}`}
                width={500}
                height={500}
                imageLayout="fill"
                allowedImageSize={{ maxWidth: 500, maxHeight: 350 }}
                allowedPdfSize={{ maxHeight: 500, maxWidth: 350 }}
                documentUrl={'/' + doc?.original}
                noDeleteOption
                className="border rounded"
              />
            ))}
          </div>
          <div className="mt-2 d-flex justify-content-end">
            <PaginationComponent
              currentPage={pageIndex}
              itemPerPage={docsPerPage.current}
              totalItems={documents.length}
              paginate={(pageNumber) => setPageIndex((_) => pageNumber)}
            />
          </div>
        </div>
      ) : (
        <div>There are no documents for this Property Card.</div>
      )}
      {isDocumentsModalOpen && (
        <Modal
          isOpen={isDocumentsModalOpen}
          toggle={toggleDocumentsModal}
          size={documents.length ? 'xl' : 'lg'}
        >
          <ModalHeader toggle={toggleDocumentsModal}>
            {documents.length ? 'Change Documents' : 'Add Documents'}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col sm={12}>
                <div className="form-group">
                  <Label htmlFor="pocket-listing-photos" className="form-label">
                    Upload Documents
                  </Label>
                  <Input
                    type="file"
                    multiple
                    name="pocket-listing-documents"
                    id="pocket-listing-documents"
                    placeholder="Upload documents..."
                    accept="image/*,application/pdf"
                    onChange={(e) => {
                      if (!e.target.files || e.target.files.length === 0) {
                        return;
                      }
                      setUploadedDocuments((_) => e.target.files);
                    }}
                  />
                </div>
              </Col>
            </Row>
            {documents.length ? (
              <>
                <hr />
                <span className="fw-semibold">Previously Uploaded Documents</span>
                {documents.length ? (
                  <div className="d-flex flex-wrap" style={{ gap: '1rem' }}>
                    {documents.map((doc, index) => (
                      <DocumentPreview
                        key={`doc-preview-${index}`}
                        width={500}
                        height={500}
                        imageLayout="fill"
                        allowedPdfSize={{ maxHeight: 500, maxWidth: 500 }}
                        documentUrl={'/' + doc?.original}
                        className="border rounded"
                        deleteHandler={() => setDocumentIndicesToDelete((prev) => [...prev, index])}
                        nonResizable
                      />
                    ))}
                  </div>
                ) : (
                  <div>There are no documents for this Property Card.</div>
                )}
              </>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleDocumentsModal}>
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={handleSubmit}
              disabled={
                (!uploadedDocuments?.length && !documentIndicesToDelete.length) ||
                submitLoading ||
                deleteLoading ||
                uploadLoading
              }
            >
              {submitLoading || deleteLoading || uploadLoading ? <Spinner size={'sm'} /> : 'Submit'}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default PocketListingDocumentList;
